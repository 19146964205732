package app.softwork.composetodo

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import app.softwork.composetodo.clients.newInstance
import app.softwork.composetodo.clients.schema
import kotlin.Unit

public interface ComposeTodoDB : SuspendingTransacter {
  public val todoQueries: TodoQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = ComposeTodoDB::class.schema

    public operator fun invoke(driver: SqlDriver, todoAdapter: Todo.Adapter): ComposeTodoDB =
        ComposeTodoDB::class.newInstance(driver, todoAdapter)
  }
}
