package app.softwork.composetodo.clients

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import app.softwork.composetodo.ComposeTodoDB
import app.softwork.composetodo.Todo
import app.softwork.composetodo.TodoQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass

internal val KClass<ComposeTodoDB>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = ComposeTodoDBImpl.Schema

internal fun KClass<ComposeTodoDB>.newInstance(driver: SqlDriver, todoAdapter: Todo.Adapter):
    ComposeTodoDB = ComposeTodoDBImpl(driver, todoAdapter)

private class ComposeTodoDBImpl(
  driver: SqlDriver,
  todoAdapter: Todo.Adapter,
) : SuspendingTransacterImpl(driver), ComposeTodoDB {
  override val todoQueries: TodoQueries = TodoQueries(driver, todoAdapter)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE todo(
          |  id TEXT NOT NULL PRIMARY KEY,
          |  title TEXT NOT NULL,
          |  until TEXT,
          |  finished INTEGER NOT NULL,
          |  recordChangeTag TEXT
          |)
          """.trimMargin(), 0).await()
    }

    private fun migrateInternal(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      if (oldVersion <= 0 && newVersion > 0) {
        driver.execute(null, """
            |CREATE TABLE todo(
            |  id TEXT NOT NULL PRIMARY KEY,
            |  title TEXT NOT NULL,
            |  until TEXT,
            |  finished INTEGER NOT NULL,
            |  recordChangeTag TEXT
            |)
            """.trimMargin(), 0).await()
      }
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      var lastVersion = oldVersion

      callbacks.filter { it.afterVersion in oldVersion until newVersion }
      .sortedBy { it.afterVersion }
      .forEach { callback ->
        migrateInternal(driver, oldVersion = lastVersion, newVersion = callback.afterVersion +
          1).await()
        callback.block(driver)
        lastVersion = callback.afterVersion + 1
      }

      if (lastVersion < newVersion) {
        migrateInternal(driver, lastVersion, newVersion).await()
      }
    }
  }
}
