package app.softwork.composetodo

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import app.softwork.composetodo.dto.TodoDTO
import kotlin.Any
import kotlin.Boolean
import kotlin.String
import kotlinx.datetime.Instant

public class TodoQueries(
  driver: SqlDriver,
  private val todoAdapter: Todo.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> all(mapper: (
    id: TodoDTO.ID,
    title: String,
    until: Instant?,
    finished: Boolean,
    recordChangeTag: String?,
  ) -> T): Query<T> = Query(47_682_821, arrayOf("todo"), driver, "todo.sq", "all",
      "SELECT todo.id, todo.title, todo.until, todo.finished, todo.recordChangeTag FROM todo") {
      cursor ->
    mapper(
      todoAdapter.idAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)!!,
      cursor.getString(2)?.let { todoAdapter.untilAdapter.decode(it) },
      cursor.getBoolean(3)!!,
      cursor.getString(4)
    )
  }

  public fun all(): Query<Todo> = all { id, title, until, finished, recordChangeTag ->
    Todo(
      id,
      title,
      until,
      finished,
      recordChangeTag
    )
  }

  public fun allIDs(): Query<TodoDTO.ID> = Query(-1_115_182_189, arrayOf("todo"), driver, "todo.sq",
      "allIDs", "SELECT id FROM todo") { cursor ->
    todoAdapter.idAdapter.decode(cursor.getString(0)!!)
  }

  public fun <T : Any> `get`(id: TodoDTO.ID, mapper: (
    id: TodoDTO.ID,
    title: String,
    until: Instant?,
    finished: Boolean,
    recordChangeTag: String?,
  ) -> T): Query<T> = GetQuery(id) { cursor ->
    mapper(
      todoAdapter.idAdapter.decode(cursor.getString(0)!!),
      cursor.getString(1)!!,
      cursor.getString(2)?.let { todoAdapter.untilAdapter.decode(it) },
      cursor.getBoolean(3)!!,
      cursor.getString(4)
    )
  }

  public fun `get`(id: TodoDTO.ID): Query<Todo> = get(id) { id_, title, until, finished,
      recordChangeTag ->
    Todo(
      id_,
      title,
      until,
      finished,
      recordChangeTag
    )
  }

  public suspend fun upsertTodo(
    id: TodoDTO.ID,
    title: String,
    until: Instant?,
    finished: Boolean,
    recordChangeTag: String?,
  ) {
    driver.execute(1_772_868_145,
        """INSERT OR REPLACE INTO todo(id, title, until, finished, recordChangeTag) VALUES (?, ?, ?, ?, ?)""",
        5) {
          bindString(0, todoAdapter.idAdapter.encode(id))
          bindString(1, title)
          bindString(2, until?.let { todoAdapter.untilAdapter.encode(it) })
          bindBoolean(3, finished)
          bindString(4, recordChangeTag)
        }.await()
    notifyQueries(1_772_868_145) { emit ->
      emit("todo")
    }
  }

  public suspend fun delete(id: TodoDTO.ID) {
    driver.execute(-1_035_731_001, """DELETE FROM todo WHERE id = ?""", 1) {
          bindString(0, todoAdapter.idAdapter.encode(id))
        }.await()
    notifyQueries(-1_035_731_001) { emit ->
      emit("todo")
    }
  }

  public suspend fun deleteAll() {
    driver.execute(-417_130_406, """DELETE FROM todo""", 0).await()
    notifyQueries(-417_130_406) { emit ->
      emit("todo")
    }
  }

  private inner class GetQuery<out T : Any>(
    public val id: TodoDTO.ID,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("todo", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("todo", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(47_688_378,
        """SELECT todo.id, todo.title, todo.until, todo.finished, todo.recordChangeTag FROM todo WHERE id = ?""",
        mapper, 1) {
      bindString(0, todoAdapter.idAdapter.encode(id))
    }

    override fun toString(): String = "todo.sq:get"
  }
}
