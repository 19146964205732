package app.softwork.composetodo

import app.cash.sqldelight.ColumnAdapter
import app.softwork.composetodo.dto.TodoDTO
import kotlin.Boolean
import kotlin.String
import kotlinx.datetime.Instant

public data class Todo(
  public val id: TodoDTO.ID,
  public val title: String,
  public val until: Instant?,
  public val finished: Boolean,
  public val recordChangeTag: String?,
) {
  public class Adapter(
    public val idAdapter: ColumnAdapter<TodoDTO.ID, String>,
    public val untilAdapter: ColumnAdapter<Instant, String>,
  )
}
